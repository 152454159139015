.radioStyleDown
{
    margin-top: 10%;
}
/* .small_price{
    margin-left: 10%;
} */
.option_name{
    margin-left: 20%;
}
/* input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #589442;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
} */
/* input[type="checkbox"]#checkbox1:checked + span {
    border-color: red;
  background-color:red;
} */
.AddTimes{
    border: none;
    /* background-color: none; */
    background: none;
    color: #589442;
   
}
.AddTimes:hover,.AddTimes:focus{
    border: none;
    cursor: pointer;
    color: #20580c;
    background: none;
    background-color: none;
    outline: none;
    border: none;
}

.removeTimes{
    border: none;
    /* background-color: none; */
    background: none;
    color: #E74747;
   
}
/* .removeTimes:active{
  
} */
.removeTimes:hover,.removeTimes:focus{
    border: none;
    font-weight: bold;
    outline: none;
    border: none;
    cursor: pointer;
    color: #e02020;
    background: none;
    background-color: none;
    
}
.fonticon{
    font-size:16px !important;

}