.rateRange::-moz-range-track {
      
    background: rgb(248, 68, 100) !important;
    cursor: pointer;
  }
  .rateRange::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04AA6D;
    cursor: pointer;
  }
  #react-paginate ul {
    display: inline-block;
    margin-left: 20px;
    padding-left: 0;
 }
 
 #react-paginate li {
    display: inline-block;
    /* border: 1px solid rgb(224, 224, 224); */
    color: #000;
    cursor: pointer;
    margin-right: 5px;
    /* border-radius: 3px; */
    margin-bottom: 5px;
 }
 
  #react-paginate li a {
      padding: 2px 5px;
      display: inline-block;
      color: #000;
      outline: none;
  }
 
 #react-paginate li.active {
    background: rgb(224, 224, 224);
    outline: none;
 }
 .blog-top {
	margin-top: 155px;
}
 