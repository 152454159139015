.borderBtn{
    background-color: white;
    border:none;
    font-size:20px;
}
.continueBtn{
    border: none;
    background: #589442;
    color: white;
    font-weight: 800;
    font-size: 16px;
}
.otp-field{
    width: 10%;
    height: 20%;
    margin-left: 5px;
    border-radius: 5%;
    border:1px solid#589442;
}
.otp-field:focus{
    width: 10%;
    height: 20%;
    margin-left: 5px;
    border-radius: 5%;
    border:1px solid#589442;
}
.Btngreen{
    border: none;
    background: #589442;
    color: white;
    font-weight: 800;
    font-size: 14px;
}
.BtnRed{
    border: none;
    background: #bb2d1b;
    color: white;
    font-weight: 800;
    font-size: 14px;
}
.verifyOtp{
    border: none;
    background: #589442;
    color: white;
    font-weight: 800;
    font-size: 16px;
}
.header1 {
    width: 100%;
    height: 60px;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 20px;
  }
 .inputsearchhidden{
margin-left: 15%;
display: none;
 }
 .inputsearchshow{
    margin-left: 15%;
 display: block;
     }
     .btncolor
     {
         color:#589442;
         border-radius: 5px;
         border:#589442 1px solid;
         margin-top: 20%;
         /* font-size:14px; */
         font-weight: bold;
     }
     /* The Modal (background) */
     .btninto {
        background: none;
        color: #589442;
        border: none;
        font-weight: bold;
        font-size: 16px;
        margin-top: 5%;
        font-size: 16px;
    }
     
    .btninto:focus 
    {
        border:2px solid #589442;; /* here configure as your needs */
        /* color:#00c; */
     }
    .locationsearch {
        width: 180%;
        height: 80%;
        /* border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 1px solid #FF3300; */
    }
   .mtsearch{
       margin-top: 20%;
    
   }
   .captalizeProfile{
       text-transform: capitalize;
   }